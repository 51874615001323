import CompanyIcon from '@blackhyve/common/src/icons/CompanyIcon';
import { Tooltip } from '@mui/material';

export const ResponsibleUsersColumnCell = ({ task }) => {
  const taskResponsibleUsers = task?.responsible_users?.sort((a, b) =>
    a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
  );

  if (task.id === -1) {
    return (
      <span className="cell_disabled" style={{ paddingTop: 10 }}>
        {CompanyIcon()}
      </span>
    );
  }

  if (task?.responsible_users?.length === 1) {
    return taskResponsibleUsers[0]?.name;
  }

  if (task?.responsible_users?.length > 1) {
    return (
      <>
        {taskResponsibleUsers.map((responsibleUser, index) => (
          <Tooltip arrow placement={'top'} title={responsibleUser?.name}>
            <div
              className="company-avatar company-grid-icon"
              key={responsibleUser?.id}
              style={{
                zIndex: taskResponsibleUsers.length - index,
                marginLeft: index > 0 ? '8px' : '0px',
              }}
            >
              {responsibleUser?.first_name?.slice(0, 1)}
              {responsibleUser?.last_name?.slice(0, 1)}
            </div>
          </Tooltip>
        ))}
      </>
    );
  } else {
    return <span style={{ paddingTop: 10 }}>{CompanyIcon()}</span>;
  }
};
