import { useState } from 'react';
import random from 'lodash/random';
import Chip from '@mui/material/Chip';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import { TASK_COLORS } from 'helpers/constants';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import { DatePicker } from '@mui/x-date-pickers';
import { StyledDialog } from '@blackhyve/common';
import { selectCurrentUser } from 'features/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import CropImage from 'features/locations/components/CropImage';
import PdfToImage from 'features/locations/components/PDFToImage';
import { addWorkdays, differenceInWorkdays, parseDate } from '@blackhyve/utilities/dates';
import { InputFileUpload } from 'components/common/ImageUploadComponent';
import {
  useCreateProductionTaskMutation,
  useCreateTaskMutation,
  useUpdateProductionTaskMutation,
  useUpdateTaskMapMutation,
  useUpdateTaskMutation,
  useUpdateTasksMutation,
} from 'features/tasks/store/task.api';
import { useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';
import isEmpty from 'lodash/isEmpty';
import { getCroppedImg } from 'features/locations/utils/cropImageHelper';
import {
  useGetProjectHolidaysQuery,
  useGetProjectQuery,
} from 'features/projects/store/project.api';
import { getCalendarDays } from 'features/tasksGantt/utils/gantt/taskGanttEvents';

const initialState = {
  name: '',
  trades: [],
  crew_size: null,
  scheduled_start: new Date(),
  scheduled_end: addWorkdays(new Date(), 7),
  companies: [],
  responsible_users: [],
};

/**
 * Create Production Board Task Dialog
 * @param {Boolean} open
 * @param {String} parentId
 * @param {String} projectId
 * @param {Object} handleClose
 * @param {Object} task
 * @param {Number} task.id
 * @param {String} task.name
 * @param {String} task.scheduled_start
 * @param {String} task.scheduled_end
 * @param {[Object]} task.trades
 * @param {[Object]} task.companies
 * @param {[Object]} task.responsible_users
 * @returns
 */
const ProductionBoardCreateListDialog = ({ open, parentId, projectId, handleClose, task }) => {
  const formMethods = useForm({
    defaultValues: { ...initialState },
  });
  const [pdf, setPdf] = useState();
  const { data: project, isLoading: isLoadingProject } = useGetProjectQuery(projectId);
  const { control, handleSubmit, watch, reset, setFocus, setValue } = formMethods;
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({ projectId });
  const [createTask, { isLoading: isCreateTaskLoading }] = useCreateProductionTaskMutation();
  const [updateTask, { isLoading: isTaskUpdating }] = useUpdateProductionTaskMutation();
  const { data: { listOfHolidays = [] } = {}, isLoading: isLoadingProjectHolidays } =
    useGetProjectHolidaysQuery(projectId);
  const currentUser = useSelector((state) => selectCurrentUser(state));
  const users = useMemo(() => {
    const users =
      currentUser.role === 'super_admin'
        ? [{ ...currentUser, isCurrentUser: true }, ...userCompany.users]
        : [...userCompany.users];
    return users;
  }, [userCompany, currentUser]);

  const startDate = watch('scheduled_start');
  const originalMap = watch('original_map');

  const handleSaveAndAddAnother = (data, e) => {
    handleSave(data, e, true);
  };

  const handleSave = async (data, e, preventClose) => {
    const isUpdate = !!data.id;
    const startDate = data.scheduled_start ? format(data.scheduled_start, 'yyyy-MM-dd') : null;
    const endDate = data.scheduled_end ? format(data.scheduled_end, 'yyyy-MM-dd') : null;
    const taskPayload = {
      ...data,
      parent_id: parentId,
      project_id: projectId,
      is_parent: 1,
      is_production: 1,
      map_view_data: !isEmpty(data.map_view_data) ? JSON.stringify(data.map_view_data) : null,
      responsible_users: data?.responsible_users?.length ? data.responsible_users : [],
      scheduled_start: startDate,
      scheduled_end: endDate,
      forecasted_start: startDate,
      forecasted_end: endDate,
      should_autoschedule: 1,
      duration_days: differenceInWorkdays(data.scheduled_start, data.scheduled_end, {
        holidays: listOfHolidays,
        days: getCalendarDays(project.workday_calendar),
      }),
    };
    let croppedImage;
    const taskOriginalMap = isUpdate
      ? data.original_map || task?.map?.original_image
      : data.original_map;
    if (data.map_view_data && taskOriginalMap) {
      const shouldCropImage = isUpdate
        ? task?.map?.map_view_data !== taskPayload.map_view_data
        : true;
      if (shouldCropImage) {
        croppedImage = await getCroppedImg(
          taskOriginalMap?.original_url || URL.createObjectURL(taskOriginalMap),
          data.map_view_data.croppedAreaPixels,
          data.map_view_data.rotate,
          taskOriginalMap?.file_name || taskOriginalMap?.name || `${task.id || 'new'}-map.png`
        );
        taskPayload['map'] = croppedImage?.croppedImageFile;
      }
    }

    const saveAction = isUpdate ? updateTask : createTask;
    saveAction({ task: taskPayload }).then(() => {
      handleReset();
      !preventClose && handleClose();
    });
  };

  const handleReset = useCallback(() => {
    setFocus('name');
    reset({
      id: task?.id ?? initialState.id,
      name: task?.name ?? initialState.name,
      color: task?.color ?? TASK_COLORS[random(0, 44)].value,
      scheduled_start: task?.scheduled_start
        ? parseDate(task?.scheduled_start)
        : initialState.scheduled_start,
      scheduled_end: task?.scheduled_end
        ? parseDate(task?.scheduled_end)
        : initialState.scheduled_end,
      responsible_users:
        task?.responsible_users?.map((user) => user?.id) ?? initialState.responsible_users,
      map_view_data: task?.map?.map_view_data
        ? JSON.parse(task?.map?.map_view_data)
        : initialState.map_view_data,
      original_map: '',
    });
  }, [
    task?.id,
    task?.name,
    task?.color,
    task?.scheduled_start,
    task?.scheduled_end,
    task?.responsible_users,
    task?.map?.map_view_data,
    reset,
    setFocus,
  ]);

  const isLoading =
    isLoadingProject || isCreateTaskLoading || isTaskUpdating || isLoadingProjectHolidays;

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={handleClose}
      maxWidth={'md'}
      open={open}
      title={'Create List'}
      actions={
        <>
          <Button disabled={isLoading} size="small" onClick={handleClose}>
            Close
          </Button>
          {!task?.id && (
            <LoadingButton
              loading={isLoading}
              size="small"
              variant={'contained'}
              onClick={handleSubmit(handleSaveAndAddAnother)}
            >
              Save And Add Another
            </LoadingButton>
          )}
          <LoadingButton
            loading={isLoading}
            size="small"
            variant={'contained'}
            onClick={handleSubmit(handleSave)}
          >
            Save
          </LoadingButton>
        </>
      }
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(handleSave)(e);
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormLabel>Name*</FormLabel>
            <Controller
              control={control}
              name="name"
              rules={{ required: 'Name field is required' }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  inputRef={ref}
                  placeholder={'Name'}
                  size="small"
                  type={'text'}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormLabel>Start Date*</FormLabel>
            <Controller
              control={control}
              name={'scheduled_start'}
              render={({
                field: { onChange, value, ref, ...field },
                fieldState: { error, invalid },
              }) => (
                <DatePicker
                  ref={ref}
                  value={value}
                  slotProps={{
                    textField: {
                      id: 'scheduled_start',
                      fullWidth: true,
                      size: 'small',
                      helperText: error?.message,
                      error: invalid,
                      ...field,
                    },
                    actionBar: {
                      actions: ['today'],
                    },
                  }}
                  onChange={onChange}
                />
              )}
              rules={{
                required: 'Field is required',
                validate: {
                  isDate: (v) => isValid(v) || 'Invalid Date',
                },
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormLabel>End Date*</FormLabel>
            <Controller
              control={control}
              name={'scheduled_end'}
              render={({
                field: { onChange, value, ref, ...field },
                fieldState: { error, invalid },
              }) => (
                <DatePicker
                  minDate={startDate}
                  ref={ref}
                  value={value}
                  slotProps={{
                    textField: {
                      id: 'scheduled_end',
                      fullWidth: true,
                      size: 'small',
                      helperText: error?.message,
                      error: invalid,
                      ...field,
                    },
                    actionBar: {
                      actions: ['today'],
                    },
                  }}
                  onChange={onChange}
                />
              )}
              rules={{
                required: 'Field is required',
                validate: {
                  isDate: (v) => isValid(v) || 'Invalid Date',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Responsible</FormLabel>
            <Controller
              control={control}
              name="responsible_users"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  disableCloseOnSelect
                  fullWidth
                  multiple
                  isOptionEqualToValue={(option, value) => option.id === value}
                  options={users}
                  getOptionLabel={(option) =>
                    option?.first_name ??
                    (users?.length && users.find((user) => user.id === option)?.first_name)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={error}
                      helperText={error?.message}
                      placeholder="Select User"
                      size="small"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <ListItem
                      {...props}
                      disablePadding
                      key={option.id}
                      secondaryAction={
                        option.isCurrentUser ? (
                          <Chip color="secondary" label="Logged in" size="small" />
                        ) : null
                      }
                    >
                      <ListItemIcon>
                        <Checkbox checked={selected} />
                      </ListItemIcon>
                      <ListItemText primary={`${option.first_name} ${option.last_name}`} />
                    </ListItem>
                  )}
                  onChange={(event, newValue) => {
                    field.onChange(newValue.map((user) => user?.id ?? user));
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Image</FormLabel>
            <div>
              {originalMap || (task?.map?.original_image?.original_url && originalMap !== null) ? (
                <>
                  <Controller
                    control={control}
                    defaultValue={'{}'}
                    name={'map_view_data'}
                    render={({ field: { onChange, value, ...field } }) => (
                      <CropImage
                        cropData={value}
                        image={originalMap || task?.map?.original_image?.original_url}
                        handleCropComplete={(cropData) => {
                          onChange(cropData);
                        }}
                      />
                    )}
                  />
                  <Link
                    color={'error'}
                    component={'button'}
                    type={'button'}
                    onClick={() => setValue('original_map', null)}
                  >
                    Remove
                  </Link>
                </>
              ) : pdf ? (
                <PdfToImage
                  pdf={pdf}
                  onSelect={(blob) => {
                    setPdf(undefined);
                    setValue('original_map', blob);
                  }}
                />
              ) : (
                <Controller
                  control={control}
                  defaultValue={''}
                  name="original_map"
                  render={({ field: { onChange, ...field } }) => (
                    <InputFileUpload
                      ButtonProps={{ size: 'small' }}
                      label={'Upload Image'}
                      inputProps={{
                        accept: 'image/x-png,image/gif,image/jpeg,application/pdf',
                        onChange: (e) => {
                          const file = e.target.files[0];
                          if (file.type === 'application/pdf') {
                            setPdf(file);
                          } else {
                            onChange(file);
                          }
                        },
                      }}
                    />
                  )}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </form>
    </StyledDialog>
  );
};

export default ProductionBoardCreateListDialog;
