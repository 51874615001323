import { lockIconString } from 'components/projectOverview/gantt/ganttConfig/column/columnConfig';

export const templates = (gantt) => {
  return {
    task_class: function (start, end, task) {
      let classes = [];
      if (gantt?.config?.show_critical_path && task.critical_path) {
        classes.push('gantt_critical_task');
      }
      if (this?.config?.highlight_task_pace) {
        if (task.status === 'active' || task.status === 'complete') {
          if (task.pace <= 0) {
            classes.push('on_pace_task');
          } else if (task.pace > 0) {
            classes.push('behind_pace_task');
          }
        } else {
          classes.push('no_pace_task');
        }
      }
      if (task?.status === 'complete') {
        classes.push('complete_task');
      }
      if (task?.status === 'active' && task.type !== 'parent_task') {
        classes.push('active_task');
      }
      if (task.type === 'parent_task') {
        classes.push('gantt_project gantt_bar_project');
      }
      if (task.type === 'project_bar') {
        classes.push('gantt_project gantt_bar_project');
      }
      if (task?.dates_locked_by) {
        classes.push('scheduled_dates_locked');
      }
      if (gantt?.config?.readonly || task?.readonly) {
        classes.push('readonly');
      }
      if (task.$isHighlighted) {
        classes.push('highlighted');
      }
      return classes.join(' ');
    },
    grid_row_class: function (start, end, task) {
      let classes = [];
      if (gantt?.config?.readonly || task?.readonly) classes.push('readonly');
      if (task.status === 'complete') classes.push('completed_task');
      if (task.$virtual) classes.push('group_row');
      if (task.type === 'parent_task') {
        classes.push('gantt_project_grid_row');
      }
      if (task.empty_row) {
        return "empty_row";
      }
      return classes.join(' ');
    },
    task_row_class: function (start, end, task) {
      const classes = [];
      if (task.$virtual) classes.push('group_row');

      if (classes.length > 0) {
        return classes.join(' ');
      }
    },
    task_text: function () {
      return '';
    },
    rightside_text: function (start, end, task) {
      const displayOptions = gantt?.config?.display || {};
      const toolOptions = gantt?.config?.tools || {};
      const tools = ['missingDependencies', 'jobwalkOpen', 'openStuckPoints']
        .filter((option) => Object.hasOwn(toolOptions, option))
        .map((option) => toolOptions[option]?.render(task, gantt));
      const display = ['company', 'status', 'name', 'location', 'zone', 'area']
        .filter((option) => Object.hasOwn(displayOptions, option))
        .map((option) => displayOptions[option]?.render(task, gantt));
      return `<div class='task-bar-label'>
        ${[...tools, task.dates_locked_by ? lockIconString : '', ...display]
          .filter((option) => option !== '')
          .join('&nbsp')}
      </div > `;
    },
    link_class: function (link) {
      const classes = [`source-${link.source}`, `target-${link.target}`];
      if (gantt?.config?.show_critical_path && gantt.getTask(link.target)?.critical_path) {
        classes.push('gantt_critical_link');
      }
      if (link.$isHighlighted) {
        classes.push('highlighted');
      }
      return classes.join(' ');
    },
    timeline_cell_class: (task, date) => {
      if (
        !gantt.isWorkTime({ task: task, date: date }) &&
        gantt?.ext?.zoom.getCurrentLevel() === 4
      ) {
        return 'weekend';
      }
      return '';
    },
  };
};
