import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import DisplayWithEditAccess, { useEditContext } from 'components/common/v3/DisplayWithEditAccess';
import { PreviewUnpublishedTargets } from 'features/targets/components/PreviewUnpublishedTargets';
import { targetRoutes } from 'features/targets/routes';
import { useGetTasksQuery, useUpdateProductionTaskMutation } from 'features/tasks/store/task.api';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import ProductionBoardCreateListDialog from '../components/ProductionBoardCreateListDialog';
import ProductionBoardCreateTaskDialog from '../components/ProductionBoardCreateTaskDialog';
import ProductionBoardListBreadcrumb from '../components/ProductionBoardListBreadcrumb';
import ProductionBoardParentTask from '../components/ProductionBoardParentTask';
import {
  ProductionBoardRow,
  ProductionBoardRowChildren,
  ProductionBoardSkeletonList,
  ProductionBoardTaskRow,
} from '../components/ProductionBoardRow';
import ProductionBoardTaskDetails from '../components/ProductionBoardTaskDetails';

const ProductionBoard = () => {
  const navigate = useNavigate();
  return (
    <Stack alignItems={'center'}>
      <Stack alignItems={'center'} direction={'row'} width={1}>
        <Box flex={1}>
          <Button
            startIcon={<ArrowBack />}
            sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box>
        <Box flex={'auto'}>
          <ProductionBoardListBreadcrumb />
        </Box>
        <Box flex={1}></Box>
      </Stack>
      <Outlet />
    </Stack>
  );
};

export const ProductionBoardHeader = ({ title, id = null, isLoading }) => {
  const projectId = Number(useParams().projectId) || undefined;
  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openCreateListDialog, setOpenCreateListDialog] = useState(false);

  const handleOpenCreateTaskDialog = () => setOpenCreateTaskDialog(true);
  const handleCloseCreateTaskDialog = () => setOpenCreateTaskDialog(false);

  const handleOpenCreateListDialog = () => setOpenCreateListDialog(true);
  const handleCloseCreateListDialog = () => setOpenCreateListDialog(false);

  return (
    <>
      <Grid item xs={12}>
        {isLoading ? (
          <Skeleton height={40} variant="text" />
        ) : (
          <Typography align="center" component="div" sx={{ fontWeight: 'bold' }} variant="h6">
            {title}
          </Typography>
        )}
      </Grid>
      <DisplayWithEditAccess>
        <Grid container item gap={1} justifyContent="center" xs={12}>
          <Button size="small" variant="contained" onClick={handleOpenCreateListDialog}>
            + Add List
          </Button>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={handleOpenCreateTaskDialog}
          >
            + Add Task
          </Button>
        </Grid>
        {openCreateTaskDialog && (
          <ProductionBoardCreateTaskDialog
            handleClose={handleCloseCreateTaskDialog}
            open={openCreateTaskDialog}
            parentId={id}
            projectId={projectId}
          />
        )}

        {openCreateListDialog && (
          <ProductionBoardCreateListDialog
            handleClose={handleCloseCreateListDialog}
            open={openCreateListDialog}
            parentId={id}
            projectId={projectId}
          />
        )}
      </DisplayWithEditAccess>
    </>
  );
};

/**
 * Project Tasks
 * @returns
 */
const ProductionBoardProjectTasks = () => {
  const workspaceId = Number(useParams().workspaceId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const [taskIdsPriority, setTaskIdsPriority] = useState([]);
  const [updateTask] = useUpdateProductionTaskMutation();
  const {
    data: taskData,
    isLoading,
    isFetching,
  } = useGetTasksQuery({ projectId, children_of: 0, include: 'map' });

  const handleUpdateTaskPriority = ({ destination, source }) => {
    const cloneTaskPriority = [...taskIdsPriority];
    if (isEmpty(destination)) {
      return false;
    }

    const destinationIndex = destination.index;
    const sourceIndex = source.index;
    const task = cloneTaskPriority.splice(sourceIndex, 1);
    cloneTaskPriority.splice(destinationIndex, 0, ...task);

    const updatedTaskId = cloneTaskPriority[destinationIndex];
    const updatedTask = { ...taskData.entities[updatedTaskId] };

    const nextSibling = taskData.entities[cloneTaskPriority[destinationIndex + 1]];
    const prevSibling = taskData.entities[cloneTaskPriority[destinationIndex - 1]];

    if (nextSibling) {
      updatedTask.target = nextSibling.id;
    } else if (prevSibling) {
      updatedTask.target = `next:${prevSibling.id}`;
    } else {
      updatedTask.target = 'next:null';
    }

    setTaskIdsPriority(cloneTaskPriority);
    updateTask({ task: { id: updatedTask.id, target: updatedTask.target } });
  };

  useEffect(() => {
    setTaskIdsPriority(taskData?.ids || []);
  }, [taskData?.ids]);

  return (
    <Container maxWidth={'xl'}>
      <Grid container alignItems="center" gap={1} justifyContent="center">
        <ProductionBoardHeader title="Production Board" />
        <PreviewUnpublishedTargets projectId={projectId} taskId={0} />
        <Grid container item gap={0.5} xs={12}>
          <DragDropContext onDragEnd={handleUpdateTaskPriority}>
            <Droppable droppableId={'task-list'} type={projectId}>
              {(provided) => (
                <Grid item xs={12} {...provided.droppableProps} ref={provided.innerRef}>
                  {!isLoading && isFetching && (
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                  )}
                  {isLoading ? (
                    <ProductionBoardSkeletonList />
                  ) : (
                    taskIdsPriority?.map((taskId, index) => (
                      <TaskRow index={index} task={taskData.entities[taskId]} />
                    ))
                  )}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </Grid>
    </Container>
  );
};

export const TaskRow = ({ index, task }) => {
  const canEdit = useEditContext();
  return (
    <Draggable
      draggableId={`task-${task?.id}`}
      index={index}
      isDragDisabled={!canEdit}
      key={`task-${task?.id}`}
    >
      {(provided, snapshot) => (
        <Grid {...provided.draggableProps} container item ref={provided.innerRef}>
          {task.is_parent ? (
            <ProductionBoardRow
              dragHandler={{ ...provided.dragHandleProps }}
              key={task?.id}
              task={task}
            >
              <ProductionBoardChildTask projectId={task.project_id} taskId={task?.id} />
            </ProductionBoardRow>
          ) : (
            <ProductionBoardTaskRow
              dragHandler={{ ...provided.dragHandleProps }}
              rowProps={{ component: Paper, elevation: 3 }}
              task={task}
            />
          )}
        </Grid>
      )}
    </Draggable>
  );
};

export const ProductionBoardChildTask = ({ projectId, taskId }) => {
  const { data, isLoading: isLoadingTasks } = useGetTasksQuery({ projectId, children_of: taskId });

  return (
    <Grid container item gap={1} xs={12}>
      {isLoadingTasks ? (
        <ProductionBoardSkeletonList isChildrenList={true} />
      ) : (
        data?.ids?.map((childId) => (
          <ProductionBoardRowChildren key={childId} task={data.entities[childId]} />
        ))
      )}
    </Grid>
  );
};

export const ProductionBoardRoutes = [
  {
    path: '',
    element: <ProductionBoard />,
    children: [
      { index: true, element: <ProductionBoardProjectTasks /> },
      {
        path: 'tasks/:taskId',
        children: [
          { index: true, element: <ProductionBoardParentTask /> },
          { path: 'details', element: <ProductionBoardTaskDetails /> },
          { path: 'targets', children: targetRoutes },
          { path: '*', element: <Navigate to={'.'} /> },
        ],
      },
    ],
  },
];
