import CompanyIcon from '@blackhyve/common/src/icons/CompanyIcon';
import { Tooltip } from '@mui/material';

export const ContactsColumnCell = ({ task }) => {
  const taskContacts = task?.contacts?.sort((a, b) =>
    a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
  );

  if (task.id === -1) {
    return (
      <span className="cell_disabled" style={{ paddingTop: 10 }}>
        {CompanyIcon()}
      </span>
    );
  }

  if (task?.contacts?.length === 1) {
    return taskContacts[0]?.name;
  }

  if (task?.contacts?.length > 1) {
    return (
      <>
        {taskContacts.map((contact, index) => (
          <Tooltip arrow placement={'top'} title={contact?.name}>
            <div
              className="company-avatar company-grid-icon"
              key={contact?.id}
              style={{
                zIndex: taskContacts.length - index,
                marginLeft: index > 0 ? '8px' : '0px',
              }}
            >
              {contact?.first_name?.slice(0, 1)}
              {contact?.last_name?.slice(0, 1)}
            </div>
          </Tooltip>
        ))}
      </>
    );
  } else {
    return <span style={{ paddingTop: 10 }}>{CompanyIcon()}</span>;
  }
};
