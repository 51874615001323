import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Print from '@mui/icons-material/Print';
import { Card, CardHeader, CardMedia, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import DisplayWithEditAccess from 'components/common/v3/DisplayWithEditAccess';
import SearchBox from 'components/search/SearchBox';
import format from 'date-fns/format';
import { MapImage } from 'features/locations/components/MapImage';
import { PreviewUnpublishedTargets } from 'features/targets/components/PreviewUnpublishedTargets';
import {
  useGetTaskQuery,
  useGetTasksQuery,
  useUpdateProductionTaskMutation,
} from 'features/tasks/store/task.api';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { TaskRow } from '../routes';
import ProductionBoardCreateListDialog from './ProductionBoardCreateListDialog';
import ProductionBoardCreateTaskDialog from './ProductionBoardCreateTaskDialog';
import { ProductionBoardSkeletonList } from './ProductionBoardRow';
const dateFormat = 'MMM dd, yyyy';

/**
 * Production Board Parent Task
 * @returns
 */
const ProductionBoardParentTask = () => {
  const taskId = Number(useParams().taskId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;

  return (
    <Grid container>
      <Grid item md={4} p={2} xs={12}>
        <DetailsComponent projectId={projectId} taskId={taskId} />
      </Grid>
      <Grid item md="auto" sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs>
        <ListComponent projectId={projectId} taskId={taskId} />
      </Grid>
    </Grid>
  );
};

/**
 * Production Board Parent Task Details
 * @param {number} id
 * @param {string} title
 * @param {boolean} isLoading
 * @returns
 * @param {number} id
 * @param {string} title
 * @param {boolean} isLoading
 * @returns
 */
const DetailsComponent = ({ taskId, projectId }) => {
  const {
    data: task,
    isLoading: isLoadingTask,
    isFetching: isFetchingTask,
  } = useGetTaskQuery({ taskId });

  const [isEditing, setIsEditing] = useState(false);

  const percentComplete = task?.percent_complete ? task?.percent_complete * 100 : 0;

  return (
    <Stack useFlexGap spacing={1}>
      {isLoadingTask ? (
        <Skeleton height={40} variant="text" width="100%" />
      ) : (
        <Stack alignItems={'center'} direction={'row'} spacing={1}>
          <Typography component="div" sx={{ fontWeight: 'bold' }} variant="h5">
            {task?.name}
          </Typography>
        </Stack>
      )}

      <Stack p={1}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormLabel>Planned Dates:</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {task?.scheduled_start ? format(task.scheduled_start, dateFormat) : ''} -{' '}
              {task?.scheduled_end ? format(task.scheduled_end, dateFormat) : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs={6}>
            <FormLabel>Forecasted Dates:</FormLabel>
          </Grid>
          <Grid item xs={6}>
            {task?.forecasted_start ? format(task.forecasted_start, dateFormat) : ''} -{' '}
            {task?.forecasted_end ? format(task.forecasted_end, dateFormat) : '-'}
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs={6}>
            <FormLabel>Percent Complete:</FormLabel>
          </Grid>
          <Grid container item alignItems={'center'} gap={1} xs={6}>
            <LinearProgress
              sx={{ width: '70%', height: '10px' }}
              value={percentComplete}
              variant="determinate"
            />
            <Typography color="text.secondary" variant="body2">
              {`${Math.round(percentComplete)}%`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormLabel>Leaders:</FormLabel>
          </Grid>
          <Grid item xs={6}>
            {task?.responsible_users?.length ? (
              task?.responsible_users?.map((user) => (
                <Chip
                  avatar={<Avatar alt={user?.name} src={user?.profile_image?.original_url} />}
                  key={user?.id}
                  label={user?.name}
                  variant="outlined"
                />
              ))
            ) : (
              <Typography>NA</Typography>
            )}
          </Grid>
        </Grid>
      </Stack>

      {task?.map?.image?.medium_url || task?.map?.original_image ? (
        <Card>
          <CardHeader title={'Map'} />
          <CardMedia>
            <MapImage
              alt={`${task?.name}`}
              backgroundColor={'#fff'}
              src={task?.map?.image?.medium_url || task?.map?.original_image?.original_url}
            />
          </CardMedia>
          {/* <CardActions>
            <Button>View</Button>
            <Button>Edit</Button>
          </CardActions> */}
        </Card>
      ) : null}
      <PreviewUnpublishedTargets
        projectId={projectId}
        slotProps={{ cardProps: { sx: { width: 1 } } }}
        taskId={taskId}
      />
    </Stack>
  );
};

/**
 * Production Board Task List
 * @param {number} taskId
 */
const ListComponent = ({ taskId, projectId }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [taskPriority, setTaskPriority] = useState([]);

  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openCreateListDialog, setOpenCreateListDialog] = useState(false);
  const [updateTask] = useUpdateProductionTaskMutation();

  const {
    data,
    isLoading: isLoadingTaskChildren,
    isFetching: isFetchingChildren,
  } = useGetTasksQuery({ projectId, children_of: taskId, include: 'map' });

  const taskList = useMemo(() => {
    if (!data?.ids?.length) return [];
    return data.ids.map((taskId) => data.entities[taskId]);
  }, [data]);

  const filteredTasks = useMemo(() => {
    const lowerCaseQuery = searchQuery?.toLowerCase();
    return taskList.filter((task) => task?.name?.toLowerCase()?.includes(lowerCaseQuery));
  }, [taskList, searchQuery]);

  const handleOpenCreateTaskDialog = () => setOpenCreateTaskDialog(true);
  const handleCloseCreateTaskDialog = () => setOpenCreateTaskDialog(false);

  const handleOpenCreateListDialog = () => setOpenCreateListDialog(true);
  const handleCloseCreateListDialog = () => setOpenCreateListDialog(false);

  const handleSearch = (event) => setSearchQuery(event.target.value);

  const handleUpdateTaskPriority = (data) => {
    const cloneTaskPriority = [...taskPriority];
    if (isEmpty(data.destination)) {
      return false;
    }
    const destinationIndex = data.destination.index;
    const sourceIndex = data.source.index;
    const task = cloneTaskPriority.splice(sourceIndex, 1);
    cloneTaskPriority.splice(destinationIndex, 0, ...task);

    const updatedTask = { ...cloneTaskPriority[destinationIndex] };

    const nextSibling = cloneTaskPriority[destinationIndex + 1];
    const prevSibling = cloneTaskPriority[destinationIndex - 1];
    if (nextSibling) {
      updatedTask.target = nextSibling.id;
    } else if (prevSibling) {
      updatedTask.target = `next:${prevSibling.id}`;
    } else {
      updatedTask.target = 'next:null';
    }

    setTaskPriority(cloneTaskPriority);
    updateTask({ task: { id: updatedTask.id, target: updatedTask.target } });
  };

  useEffect(() => {
    setTaskPriority(filteredTasks);
  }, [filteredTasks]);

  return (
    <Grid container item xs gap={1}>
      <Grid
        container
        item
        alignItems={'center'}
        bgcolor={'#fafafa'}
        justifyContent={'space-between'}
        justifyItems={'space-between'}
        px={2}
        py={1}
        sx={{ borderBottom: '0.5px solid #e0e0e0' }}
        xs={12}
      >
        <DisplayWithEditAccess>
          <Box>
            <Button size="small" variant="contained" onClick={handleOpenCreateListDialog}>
              Add List
            </Button>
            <Button
              color="secondary"
              size="small"
              sx={{ ml: 1 }}
              variant="contained"
              onClick={handleOpenCreateTaskDialog}
            >
              Add Task
            </Button>
          </Box>
        </DisplayWithEditAccess>
        <SearchBox
          bgcolor="grey.200"
          borderRadius={1}
          fullWidth={false}
          placeholder="Search By Name"
          value={searchQuery}
          onChange={handleSearch}
        />
        <Box>
          <IconButton disabled size={'small'}>
            <FilterAltIcon />
          </IconButton>
          <IconButton disabled size={'small'}>
            <Print />
          </IconButton>
        </Box>
      </Grid>
      <Grid container item gap={0.5}>
        <DragDropContext onDragEnd={handleUpdateTaskPriority}>
          <Droppable droppableId={'task-list'} type={taskId}>
            {(provided) => (
              <Grid item xs={12} {...provided.droppableProps} ref={provided.innerRef}>
                {!isLoadingTaskChildren && isFetchingChildren && (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                )}
                {isLoadingTaskChildren ? (
                  <ProductionBoardSkeletonList />
                ) : taskPriority?.length ? (
                  taskPriority?.map((task, index) => {
                    return <TaskRow index={index} key={task.id} task={task} />;
                  })
                ) : (
                  <Alert severity="info" sx={{ width: '100%' }}>
                    {isEmpty(searchQuery)
                      ? 'No tasks have been added.'
                      : `No tasks found for the search query.`}
                  </Alert>
                )}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      {openCreateTaskDialog && (
        <ProductionBoardCreateTaskDialog
          handleClose={handleCloseCreateTaskDialog}
          open={openCreateTaskDialog}
          parentId={taskId}
          projectId={projectId}
        />
      )}
      {openCreateListDialog && (
        <ProductionBoardCreateListDialog
          handleClose={handleCloseCreateListDialog}
          open={openCreateListDialog}
          parentId={taskId}
          projectId={projectId}
        />
      )}
    </Grid>
  );
};

export default ProductionBoardParentTask;
