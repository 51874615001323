import CompanyIcon from '@blackhyve/common/src/icons/CompanyIcon';
import { Tooltip } from '@mui/material';

export const CompaniesColumnCell = ({ task }) => {
  const taskCompanies = task?.companies?.sort((a, b) =>
    a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
  );

  if (task.id === -1) {
    return (
      <span className="cell_disabled" style={{ paddingTop: 10 }}>
        {CompanyIcon()}
      </span>
    );
  }

  if (task?.companies?.length === 1) {
    return taskCompanies[0]?.name;
  }

  if (task?.companies?.length > 1) {
    return (
      <>
        {taskCompanies.map((company, index) => (
          <Tooltip arrow placement={'top'} title={company?.name}>
            <div
              className="company-avatar company-grid-icon"
              key={company?.id}
              style={{
                zIndex: taskCompanies.length - index,
                marginLeft: index > 0 ? '8px' : '0px',
              }}
            >
              {company?.name?.slice(0, 2)}
            </div>
          </Tooltip>
        ))}
      </>
    );
  } else {
    return <span style={{ paddingTop: 10 }}>{CompanyIcon()}</span>;
  }
};
